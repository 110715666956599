import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { MappedImage } from '../Image'
import { mapSubModuleFields } from '../../utils'
import './flipCard.css'

export const FlipCard = ({ subModules }) => (
  <Container fluid className="fcard module-outer">
    <Row md={3} className="fcard module-inner m-auto justify-content-center">
      {subModules &&
        subModules.map((item, i) => (
          <Col key={`${item.heading}-${i}`}>
            <div className="flip-card my-3">
              <div
                className={`${
                  !item?.style?.disableFlipCard
                    ? 'flip-card-inner'
                    : 'flip-disable'
                }`}
              >
                <div className="flip-card-front">
                  <MappedImage image={item.image} />
                  <h3>
                    <span className="flip-card-title">{item.heading}</span>
                  </h3>
                  <p>{item.subtitle}</p>
                </div>

                <div className="flip-card-back">
                  <ul>
                    {item?.textList?.map((text, i) => (
                      <li key={`${text}-${i}`}>{text}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        ))}
    </Row>
  </Container>
)

export const mapFlipCardProps = props => mapSubModuleFields(props)
